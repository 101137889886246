import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ({image}) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: {eq: "hemd-und-fliege-logo.svg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      businessCard: file(relativePath: {eq: "business-card-mwri-hemd-und-fliege.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      noteBook: file(relativePath: {eq: "color-screen-mac-hemd-und-fliege.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      mobileDesign: file(relativePath: {eq: "mobile-design-hemd-und-fliege-thomas-ranft.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      grid: file(relativePath: {eq: "grid-screen.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      painting: file(relativePath: {eq: "painting-brush-hemd-und-fliege.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      codeArt: file(relativePath: {eq: "code-japanese-hemd-und-fliege.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      typography: file(relativePath: {eq: "typography-hemd-und-fliege.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      abstract: file(relativePath: {eq: "abstract-form-hemd-und-fliege.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      moritz: file(relativePath: {eq: "moritz-wright-mwri-development-hemd-und-fliege.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      kristina: file(relativePath: {eq: "kristina-raibarte-design-hemd-und-fliege.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      nikita: file(relativePath: {eq: "nikita-grabin-business-management-hemd-und-fliege.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      creation: file(relativePath: {eq: "concept-picture-one-creation.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      execution: file(relativePath: {eq: "concept-picture-two-execution.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      distribution: file(relativePath: {eq: "concept-picture-three-distribution.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      backgroundElement: file(relativePath: {eq: "background-element.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const imgSwitch = (imgCase) => {
    switch(imgCase) {
      case "business-card":
        return <Img fluid={data.businessCard.childImageSharp.fluid} />;
      case "notebook":
        return <Img fluid={data.noteBook.childImageSharp.fluid} />;
      case "mobile-design":
        return <Img fluid={data.mobileDesign.childImageSharp.fluid} />;        
      case "grid":
        return <Img fluid={data.grid.childImageSharp.fluid} />;
      case "painting":
        return <Img fluid={data.painting.childImageSharp.fluid} />;
      case "code-art":
        return <Img fluid={data.codeArt.childImageSharp.fluid} />;
      case "grtypographyid":
        return <Img fluid={data.typography.childImageSharp.fluid} />;
      case "abstract":
        return <Img fluid={data.abstract.childImageSharp.fluid} />;
      case "moritz":
        return <Img fluid={data.moritz.childImageSharp.fluid} />;
      case "kristina":
        return <Img fluid={data.kristina.childImageSharp.fluid} />;
      case "nikita":
        return <Img fluid={data.nikita.childImageSharp.fluid} />;
      case "logo":
          return <Img fluid={data.logo.childImageSharp.fluid} />;
      case "creation":
          return <Img fluid={data.creation.childImageSharp.fluid} />;
      case "execution":
          return <Img fluid={data.execution.childImageSharp.fluid} />;
      case "distribution":
          return <Img fluid={data.distribution.childImageSharp.fluid} />;
      case "background-element":
          return <Img fluid={data.backgroundElement.childImageSharp.fluid} />;
      default:
        console.log("No image, something went wrong")         
    }
  }

  return (
    <div className="img-wrapper">
      {imgSwitch(image)}
    </div>
  )
}

export default Image
