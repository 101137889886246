import React from 'react'

import "./GeneralHeadline.scss"

const GeneralHeadline = ({line, id}) => {
  
  const headSwitch = (headId) => {
    switch(headId) {
      case "sub":
        return "sub"
      case "main":
        return "main"
    }
  }  
  
  return (
        <div className={`headline-wrapper ${headSwitch(id)}`}>
          <h2>{line}</h2>
        </div>
    )
}

export default GeneralHeadline
